/**
 * 模块名称: 审批流模版
 * @author xuzhongyuan@372163.com
 */

import React, { useState, useEffect } from 'react'
import { Icon } from 'antd'
import Node from '../Node'

const PlaneLine = () => (
  <div className="plane-line">
    <span className="node-line"></span>
    <Icon type="arrow-down" />
  </div>
)

const NormalLine = () => (
  <div className="plane-line normal-line">
    <span className="node-line"></span>
    <Icon type="arrow-down" />
  </div>
)

const TopLine = ({ left, right, onlyLine }) => (
  <div className={`top-line${left ? ' left' : ''}${right ? ' right' : ''}`}>
    <span className="node-line"></span>
    {!onlyLine && <Icon type="arrow-down" />}
  </div>
)

const Temp = ({ nodes, onClick, currentId }) => {

  const [data, setData] = useState(null)

  const onClickItem = item => {
    if (item.type > 2 || item.id === currentId) {
      return
    }
    onClick(item)
  }

  useEffect(() => {
    if (nodes.length) {
      const obj = {}
      nodes.forEach(item => {
        obj['node_' + item.originalId] = item
      })
      setData(obj)
    } else {
      setData(null)
    }
  }, [nodes])

  if (!data) {
    return null
  }

  return (
    <div className="temp temp-3">
      <Node key={data.node_70.id} data={data.node_70} index={1} onClickItem={onClickItem} currentId={currentId} length={1} fullLine />
      <div className="node-branch-wrap">
        <div className="node-branch">
          <div className="node-row-1 flex">
            <TopLine left />
            <Node key={data.node_74.id} data={data.node_74} index={0} onClickItem={onClickItem} currentId={currentId} length={1} topLine />
            <div className="branch-line"></div>
          </div>
          <div className="node-row-2 flex">
            <TopLine />
            <Node key={data.node_76.id} data={data.node_76} index={0} onClickItem={onClickItem} currentId={currentId} length={1} topLine />
            <div className="branch-line"></div>
          </div>
          <div className="node-row-3">
            <div className="node-row-3-first">
              <div className="blank-node"><TopLine onlyLine /><span></span></div>
              <div className="blank-node">
                <TopLine onlyLine />
                <span></span>
              </div>
              <div className="node-wrapper">
                <TopLine />
                <Node key={data.node_78.id} data={data.node_78} index={1} onClickItem={onClickItem} currentId={currentId} length={1} fullLine />
              </div>
              <div className="blank-node"><TopLine onlyLine /><span></span></div>
              <div className="blank-node"><TopLine onlyLine /><span></span></div>
            </div>
            <div className="branch-box-wrap">

              <div className="branch-box-1 flex">
                <TopLine left />
                <Node key={data.node_81.id} data={data.node_81} index={0} onClickItem={onClickItem} currentId={currentId} length={1} />
                <div className="branch-line"></div>
              </div>

              <div className="branch-box-2 flex">

                <div className="branches-wrap">
                  <div className="node-wrapper">
                    <TopLine left />
                    <Node key={data.node_85.id} data={data.node_85} index={1} onClickItem={onClickItem} currentId={currentId} length={1} fullLine />
                  </div>
                  <div className="node-wrapper">
                    <TopLine left />
                    <Node key={data.node_87.id} data={data.node_87} index={1} onClickItem={onClickItem} currentId={currentId} length={1} fullLine />
                  </div>
                </div>
                <PlaneLine />
                <div className="branches-wrap flex-1 flex">

                  <Node key={data.node_88.id} data={data.node_88} index={0} onClickItem={onClickItem} currentId={currentId} length={1} />
                  <div className="branch-line"></div>
                </div>
              </div>

              <div className="branch-box-3">
                <div className="branch-items">
                  <div className="branches-wrap">
                    <div className="node-wrapper">
                      <TopLine left />
                      <Node key={data.node_93.id} data={data.node_93} index={1} onClickItem={onClickItem} currentId={currentId} length={1} fullLine />
                    </div>
                    <div className="branch-line"></div>
                    <div className="node-wrapper">
                      <TopLine right />
                      <Node key={data.node_95.id} data={data.node_95} index={1} onClickItem={onClickItem} currentId={currentId} length={1} fullLine />
                    </div>
                  </div>
                  <PlaneLine />
                </div>
                <div className="branches-wrap center">
                  <Node key={data.node_90.id} data={data.node_90} index={1} onClickItem={onClickItem} currentId={currentId} length={1} fullLine />
                </div>
                <div className="branches-wrap center">
                  <Node key={data.node_97.id} data={data.node_97} index={1} onClickItem={onClickItem} currentId={currentId} length={1} fullLine />
                </div>
              </div>

            </div>
          </div>
          <div className="node-row-4 flex">
            <TopLine right />
            <Node key={data.node_309.id} data={data.node_309} index={0} onClickItem={onClickItem} currentId={currentId} length={1} topLine />
            <div className="branch-line"></div>
            <Node key={data.node_310.id} data={data.node_310} index={0} onClickItem={onClickItem} currentId={currentId} length={1} topLine />
            <div className="branch-line"></div>
            <Node key={data.node_311.id} data={data.node_311} index={0} onClickItem={onClickItem} currentId={currentId} length={1} topLine />
            <div className="branch-line"></div>
          </div>
        </div>
        <NormalLine />
      </div>
      <Node key={data.node_71.id} data={data.node_71} index={0} onClickItem={onClickItem} currentId={currentId} length={1} />
    </div>
  )
}

export default Temp