/**
 * 审批流编辑布局组件
 * 
 */

import React, { useState, useEffect } from 'react'
import { Input, Button, Tabs, Radio, Checkbox, message, Tag, Icon, Drawer, Modal, Alert } from 'antd'
import api from '@/api'
import BtnGroup from '@/components/BtnGroup'
import CorpList from './CorpList'
import { useSelector, useDispatch } from 'react-redux'
import { showLoading, hideLoading } from 'global/reducer/loading'

const { TabPane } = Tabs
let selectedCorp = []

const Layout = ({
  tempId,
  children, 
  onChange,
  nodeConfig,
  itemConfig,
  disableCorps,
  nodes,
  onSetNodes,
  setNodeConfig,
  onClearNodes,
  onClearNodeConfig,
  onClearItemConfig
}) => {
  const [name, setName] = useState('')
  const [current, setCurrent] = useState(-1) //审批流id
  const [list, setList] = useState([])
  const [status, setStatus] = useState(null)
  const [nodeChecked, setNodeChecked] = useState([])
  const [sign, setSign] = useState(null)
  const [corpRange, setCorpRange] = useState([])
  const [unitChecked, setUnitChecked] = useState([])
  const [msgType, setMsgType] = useState(null)
  const [showCorpDrawer, setShowCorpDrawer] = useState(false)
  const [activeKey, setActiveKey] = useState('1')
  const [renderLoading, setRenderLoading] = useState(false)
  const [loading, setLoading] = useState(false)
  const [showList, setShowList] = useState(true)
  const [showConfig, setShowConfig] = useState(true)

  const dispatch = useDispatch()
  const isLoading = useSelector(state => state.showLoading)

  // const isOrderType = () => tempId >=25 && tempId <=30

  useEffect(() => {
    dispatch(showLoading())
    api.getProcedureTemp({ id: tempId }).then(data => {
      dispatch(hideLoading())
      setList(data.workIds)
      setName(data.link.name)
      if (data.workIds.length) {
        currentHandle(data.workIds[0])
      }
    })
    selectedCorp = []
  }, [])

  // 节点设置
  useEffect(() => {
    console.log('nodeConfig ==>', nodeConfig)
    if (nodeConfig) {
      setActiveKey('1')
      setStatus(nodeConfig.isSkip)
      const { config } = nodeConfig

      // 设置节点操作
      const current = []
      for (let k in config) {//eslint-disable-line
        if (+config[k] === 1) {
          current.push(k)
        }
      }
      setNodeChecked(current)

      // 审批方式
      setSign(config.sign)
    }
  }, [nodeConfig])


  // 流程设置
  useEffect(() => {
    //console.log('itemConfig ==>', itemConfig)
    if (itemConfig) {
      // 审批范围
      setCorpRange(itemConfig.corpRange)
      selectedCorp = itemConfig.corpRange

      // 消息提醒
      setMsgType(itemConfig.every_node_send_msg)
      
      // 流操作
      const current = []
      for (let k in itemConfig) {//eslint-disable-line
        if (itemConfig[k] == 1 && k !== 'every_node_send_msg') {
          current.push(k)
        }
      }
      setUnitChecked(current)
    }
  }, [itemConfig])

  useEffect(() => {
    if (isLoading && !renderLoading) {
      setRenderLoading(true)
      setTimeout(() => setLoading(true))
    }
    if (!isLoading && loading) {
      setLoading(false)
      setTimeout(() => setRenderLoading(false), 500)
    }
  }, [isLoading])

  const onAdd = () => {
    dispatch(showLoading())
    api.createProcedure({ tmplId: tempId }).then(data => {
      dispatch(hideLoading())
      message.success('添加成功', 2)
      setList([...list, data.id])
      currentHandle(data.id)
    })
  }

  const clear = () => {
    onClearNodes()
    onClearItemConfig()
    onClearNodeConfig()
  }

  const onSearch = (val) => {
    clear()
    api.getProcedureItems({
      id: tempId,
      searchForId: val,
      limit: global.paramsLimit
    }).then(data => {
      setList(data.workIds)
    })
  }

  // 节点操作
  const onChangeNodeCheckbox = vals => {
    // console.log(vals)
    setNodeChecked(vals)
  }

  // 选择审批流项
  const onCurrent = id => currentHandle(id)
  
  const currentHandle = id => {
    setCurrent(id)
    onChange(id)
    setActiveKey('2')
    onClearNodeConfig()
  }

  const getNodes = (data, skip) => {
    for (let i = 0; i < data.length; i++) {
      if (data[i].id === nodeConfig.id) {
        data[i].isSkip = skip
        break
      }
      else if (data[i].branchesNodesTree.length) {
        const branches = data[i].branchesNodesTree
        for (let k = 0; k < branches.length; k++) {
          for (let n = 0; n < branches[k].length; n++) {
            if (branches[k][n].id === nodeConfig.id) {
              branches[k][n].isSkip = skip
              break
            }
          }
        }
      }
    }
    return Object.assign([], data)
  }

  // 修改节点状态
  const onChangeStatus = e => {
    const val = e.target.value
    dispatch(showLoading())
    api.onSetNodeStatus({
      isTmpl: 0,
      linkId: current,
      nodeId: nodeConfig.id,
      isSkip: val
    }).then(() => {
      dispatch(hideLoading())
      setStatus(val)
      message.success('保存成功', 2)
      onSetNodes(getNodes(nodes, val))
      // onSetNodes(nodes.map(item => item.id === nodeConfig.id ? {...item, isSkip: val} : item))
    })
  }

  // 审批方式
  const onChangeSign = e => {
    dispatch(showLoading())
    api.onSetNodeConfig({
      isTeml: 0,
      linkId: current,
      nodeId: nodeConfig.id,
      name: 'sign',
      value: e.target.value
    }).then(() => {
      dispatch(hideLoading())
      setSign(e.target.value)
      nodeConfig.config.sign = e.target.value
      setNodeConfig(nodeConfig)
      message.success('保存成功', 2)
    })
  }

  const onChangeUnitCheckbox = vals => {
    setUnitChecked(vals)
  }

  // 消息提醒
  const onChangeMsgType = e => {
    const val = e.target.value
    dispatch(showLoading())
    api.onSetUnitConfig({
      isTeml: 0,
      id: current,
      name: 'every_node_send_msg',
      value: val
    }).then(() => {
      dispatch(hideLoading())
      setMsgType(val)
      message.success('保存成功', 2)
    })
  }

  // 选择审批公司
  const onSelectedCorp = data => {
    selectedCorp = data
  }

  // 保存公司审批范围
  const onSaveCorpRange = () => {
    if (selectedCorp.length === 0) {
      message.error('公司范围不能为空')      
      return
    }
    dispatch(showLoading())
    api.onSavaCorpRange({
      isTmpl: 0,
      id: current,
      companies: selectedCorp.map(item => item.id).join(',')
    }).then(() => {
      dispatch(hideLoading())
      setCorpRange(selectedCorp)
      setShowCorpDrawer(false)
      message.success('保存成功', 2)
    }).catch(err => {
      dispatch(hideLoading())
    })
  }
  
  // 重置
  const onReset = () => {
    dispatch(showLoading())
    api.onResetProcedure({id: current}).then(() => {
      dispatch(hideLoading())
      currentHandle(current)
      message.success('重置成功', 2)
    })
  }

  const onDelete = () => {
    Modal.confirm({
      title: '确认要删除这条审批流吗？',
      content: '',
      okText: '确认',
      okType: 'danger',
      cancelText: '取消',
      onOk() {
        dispatch(showLoading())
        api.onDeleteProcedure({ id: current }).then(() => {
          dispatch(hideLoading())
          message.success('删除成功', 2)
          clear()
          setList(list.filter(id => id !== current))
        }).catch(err => {
          dispatch(hideLoading())
        })
      },
      onCancel() {
        // console.log('Cancel')
      }
    })
  }

  // 节点设置操作
  const onSetNodeConfig = event => {
    const {value, checked} = event.target
    dispatch(showLoading())
    api.onSetNodeConfig({
      isTeml: 0,
      linkId: current,
      nodeId: nodeConfig.id,
      name: value,
      value: Number(checked)
    }).then(() => {
      dispatch(hideLoading())
      message.success('保存成功', 2)
      nodeConfig.config[value] = Number(checked)
      setNodeConfig(nodeConfig)
    })
  }

  // 流程设置操作
  const onSetUnitConfig = event => {
    dispatch(showLoading())
    api.onSetUnitConfig({
      isTeml: 0,
      id: current,
      name: event.target.value,
      value: Number(event.target.checked)
    }).then(() => {
      dispatch(hideLoading())
      message.success('保存成功', 2)
    })
  }

  const onChangeTabs = key => setActiveKey(key)

  const onShowList = () => {
    setShowList(!showList)
  }

  const onShowConfig = () => {
    setShowConfig(!showConfig)
  }

  //处理触发条件节点
  const dealReName = () => {

    // 后端给返回的格式
    // nodeConfig.type_2_parent_reqname = [['条件1', '条件2'], ['条件3', '条件4']]
    // nodeConfig.type_5_reqname = [['条件5', '条件6'], ['条件7', '条件8']]

    let arr = [], parentArr = []

    function ifAnd (arr){
      return JSON.stringify(arr).replace(/' '/g, '').replace(/"/g, '').replace(/'/g, '').replace('[[', '').replace(']]', '').replace('],[', ' || ').replace(/,/g, ' 且 ')
    }

    let nodePReqName = nodeConfig.type_2_parent_reqname
    if(nodePReqName && nodePReqName.length > 0){
      parentArr = ifAnd(nodePReqName).split('||')
    }else{
      parentArr = []
    }

    let nodeReName = nodeConfig.type_5_reqname
    if(nodeReName && nodeReName.length > 0){
      arr = ifAnd(nodeReName).split('||')
    }else{
      arr = []
    }

    if((nodeReName && !nodeReName.length) || !nodeReName) return '无'
    return <>
      {parentArr.length > 0 && <div>当满足：
        {
          parentArr.map((item, index, self) => {
            return index + 1 < self.length ? <div key={index}>
              <div>{item}</div><div>或者</div>
            </div> : <div key={index}>{item}</div>
          })
        }  
        <div style={{margin: '5px 0'}}>条件时：且</div>
      </div>}
      <div>
        {
          arr.map((item, index, self) => {
            return index + 1 < self.length ? <div key={index}>
              <div>{item}</div><div>或者</div>
            </div> : <div key={index}>{item}</div>
          })
        } 
      </div>
    </>
  }

  return (
    <div className="procedure-wrapper">
      <header className="procedure-header">
        <div className={`control-btn ${current!== -1?'show':''}`}>
          <Icon type="redo" onClick={onReset} title="重置" />
          <Icon type="delete" onClick={onDelete} title="删除"/>
        </div>
        <div>{name}</div>
        <div className="right"></div>
      </header>
      <div className="procedure-main">
        <div className={`procedure-control${showList?'':' hide'}`}>
          <Input.Search className="search-inp" onSearch={onSearch} />
          <Button className="add-btn" icon="plus" onClick={onAdd}>添加</Button>
          <ul className="procedure-list">
            {list.map(item => <li className={current === item ? 'current' : ''} onClick={() => onCurrent(item)} key={item}>ID：{item}</li>)}
          </ul>
          <span className="show-list-icon" onClick={onShowList}>{showList ? <Icon type="double-left" /> : <Icon type="double-right" />}</span>
        </div>

        <div className={`procedure-content${showList?'':' full-left'}${showConfig?'':' full-right'}`}>{children}</div>

        <div className={`procedure-config${showConfig?'':' hide'}`}>
          <Tabs activeKey={activeKey} animated={false} onChange={onChangeTabs}>
            <TabPane tab="节点设置" key="1">
              {nodeConfig ?
              <div className="node-item-wrap">
                <div className="node-item">触发条件：{dealReName()}</div>
                <div className="node-item">节点：{nodeConfig.name}</div>
                <div className="node-item">
                  <span className="item-title">节点状态</span>
                  <Radio.Group onChange={onChangeStatus} value={status}>
                    {/* disabled={isOrderType()} */}
                    <Radio value={0}>开启节点</Radio>
                    {/* disabled={isOrderType()} */}
                    <Radio value={1}>跳过节点</Radio>
                  </Radio.Group>
                </div>
                <div className="node-item">
                  <span className="item-title">审批人</span>
                  <ul className="person-list">
                    {nodeConfig.chargersList.map(item => <li key={item.id}>{item.name}</li>)}
                  </ul>
                </div>
                <div className="node-item">
                  <span className="item-title">操作</span>
                  <Checkbox.Group className="node-checkbox" onChange={onChangeNodeCheckbox} value={nodeChecked}>
                    {/* disabled={isOrderType()} */}
                    <Checkbox onChange={onSetNodeConfig} value="reject_msg_require">审批意见驳回必填</Checkbox>
                    {/* disabled={isOrderType()} */}
                    <Checkbox onChange={onSetNodeConfig} value="return_up">转上级</Checkbox>
                    {/* disabled={isOrderType()} */}
                    <Checkbox onChange={onSetNodeConfig} value="replenish">补充资料</Checkbox>
                    {/* 权签 */}
                    <Checkbox onChange={onSetNodeConfig} value="upload_attachment">上传附件</Checkbox>
                    {
                      nodeConfig.config.upload_info !== undefined && <Checkbox onChange={onSetNodeConfig} value="upload_info">上传资料</Checkbox>
                    }
                    <Checkbox onChange={onSetNodeConfig} value="auto_check">是否自动审批通过</Checkbox>
                  </Checkbox.Group>
                </div>
                <div className="node-item">
                  <span className="item-title">审批方式</span>
                  <Radio.Group onChange={onChangeSign} value={sign}>
                    {/* disabled={isOrderType()} */}
                    <Radio value="single">或签</Radio>
                    {/* disabled={isOrderType()} */}
                    <Radio value="jointly">会签</Radio>
                  </Radio.Group>
                </div>
              </div> : <div style={{padding: 20}}><Alert message="选择节点后设置" type="info" /></div>}
            </TabPane>
            <TabPane tab="流程设置" key="2">
              {itemConfig ?
              <div className="node-item-wrap">
                <div className="node-item">
                  <span className="item-title">审批范围 <Icon onClick={() => setShowCorpDrawer(true)} type="edit" /></span>
                  <div className="corp-range">
                    {corpRange.map(item => <Tag key={item.id} color="#108ee9">{item.name}</Tag>)}
                  </div>
                </div>
                <div className="node-item">
                  <span className="item-title">消息提醒</span>
                  <Radio.Group onChange={onChangeMsgType} value={msgType}>
                    <Radio value={1}>每个节点审批结果都向申请人发送消息</Radio>
                    <Radio value={0}>仅在审批已完成或驳回时向申请人发送消息</Radio>
                  </Radio.Group>
                </div>
                <div className="node-item">
                  <span className="item-title">操作</span>
                  <Checkbox.Group className="node-checkbox" onChange={onChangeUnitCheckbox} value={unitChecked}>
                    <Checkbox value="allow_applicant" onChange={onSetUnitConfig}>允许申请人删除被驳回的申请</Checkbox>
                    {/* 2020年04月10日10:14:46 bug4984建议审批流管理模块审批流编辑页面，允许审批人查看已删除的申请该选项隐藏 */}
                    {/* <Checkbox value="allow_examiner_view_del" onChange={onSetUnitConfig}>允许审批人查看已删除的申请</Checkbox> */}
                    <Checkbox value="allow_rejection_re_mention" onChange={onSetUnitConfig}>驳回重提</Checkbox>
                  </Checkbox.Group>
                </div>
              </div> : <div style={{padding: 20}}><Alert message="选择审批后设置" type="info" /></div>}
            </TabPane>
          </Tabs>
          <span className="show-list-icon" onClick={onShowConfig}>{showConfig ? <Icon type="double-right" /> : <Icon type="double-left" />}</span>
        </div>
        <Drawer
          visible={showCorpDrawer}
          width={450}
          onClose={() => setShowCorpDrawer(false)}
          title="设置审批流适用公司范围"
          destroyOnClose={true}
        >
          <CorpList onChange={onSelectedCorp} value={corpRange.map(item => item.id)} disableCorps={disableCorps} title="公司数据" />
          <BtnGroup style={{ marginTop: 40 }} onConfirm={onSaveCorpRange} onCancel={() => setShowCorpDrawer(false)} />
        </Drawer>
      </div>
      <div className={`loading-full ${loading ? 'showLoading' : ''}`} style={{display: renderLoading?'block':'none'}}></div>
    </div>
  )
}

export default Layout