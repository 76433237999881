import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Main from './Main'
// import Setting from './Setting'

const Procedure = ({ match, history }) => {
  return (
    <Switch>
      <Route path={match.path + "/procedure"} component={Main} />
    </Switch>
  )
}

export default Procedure