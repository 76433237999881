/**
 * 所属公司过滤取
 * 接收onChange事件 以数组的格式返回所选公司的全部数据
 */

import React, { useState, useEffect } from 'react'
import { Tag, message } from 'antd'
import api from '@/api'

const { CheckableTag } = Tag

const CorpList = props => {
  const { value, disableCorps } = props
  // console.log('disableCorps', disableCorps)
  // console.log('value', value)
  const [list, setList] = useState([])
  const [checkedAll, setCheckedAll] = useState(false)

  useEffect(() => {
    api.getCorp({ limit: global.paramsLimit }).then(data => {
      const currentList = getDisabled(getCurrent(data.list))
      if (currentList.every(item => item.checked)) {
        setCheckedAll(true)
      }
      setList(currentList)
    })
  }, [])

  useEffect(() => {
    // console.log('value', value)
    if (value && value.length) {
      setCheckedAll(false)
    }
  }, [value])

  const getCurrent = list => {
    const current = []
    for (let i = 0; i < list.length; i++) {
      let isFound = false
      for (let j = 0; j < value.length; j++) {
        if (+value[j] === list[i].id) {
          isFound = true
          current.push({ ...list[i], checked: true })
          break
        }
      }
      if (!isFound) {
        current.push({ ...list[i], checked: false })
      }
    }
    return current
  }

  const getDisabled = list => {
    const arr = []
    for (let i = 0; i < list.length; i++) {
      let isFound = false
      for (let j = 0; j < disableCorps.length; j++) {
        if (+disableCorps[j] === list[i].id) {
          isFound = true
          arr.push({ ...list[i], disable: true })
          break
        }
      }
      if (!isFound) {
        arr.push({ ...list[i], disable: false })
      }
    }
    return arr
  }

  const onChange = (checked, id) => {
    if (disableCorps.indexOf(id) !== -1) {
      message.info('已经被使用')
      return
    }

    const items = list.map(item => item.id === id ? { ...item, checked } : item)
    const checkedList = items.filter(item => item.checked)
    setList(items)
    if (checkedList.length === items.length - disableCorps.length) {
      setCheckedAll(true)
    } else {
      setCheckedAll(false)
    }
    props.onChange(checkedList)
  }

  const onChangeAll = checked => {
    if (checked) {
      const items = list.map(item => ({ ...item, checked: item.disable ? false : true }))
      setList(items)
      setCheckedAll(true)
      props.onChange(items.filter(item => item.checked))
    }
    else {
      setList(list.map(item => ({ ...item, checked: false })))
      setCheckedAll(false)
      props.onChange([])
    }
  }

  return (
    <div className="corp-filter-wrap">
      {/* <label>{props.title || '所属公司'}：</label> */}
      <div>
        <CheckableTag checked={checkedAll} onChange={checked => onChangeAll(checked)}>全部</CheckableTag>
        {list.map(d => <CheckableTag className={d.disable?'tag-disable':''} key={d.id} checked={d.checked} onChange={checked => onChange(checked, d.id)}>{d.name}</CheckableTag>)}
      </div>
    </div>
  )
}

export default CorpList