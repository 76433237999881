/**
 * 模块名称: 审批流模版
 * @author xuzhongyuan@372163.com
 */

import React from 'react'
import Node from '../Node'

const Temp = ({ nodes, onClick, currentId }) => {

  const onClickItem = item => {

    if (item.type > 2 || item.id === currentId) {
      return
    }

    onClick(item)
  }

  return (
    <div className="temp temp-1">
      {nodes.map((item, index) => (
        <Node key={item.id} data={item} index={index} onClickItem={onClickItem} currentId={currentId} length={nodes.length} />
      ))}
    </div>
  )
}

export default Temp