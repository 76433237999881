/**
 * 模块名称: 审批流
 * @author xuzhongyuan@372163.com
 */

import React, { useState, useEffect } from 'react'
import BtnGroup from '@/components/BtnGroup'
import { Table, Alert, Form, Input, Icon } from 'antd'
import api from '@/api'
import { parseSearch } from '@/utils'
import { Link } from 'react-router-dom'
import Auth from '@/components/AuthMiddleware'

const Column = Table.Column
const FormItem = Form.Item

let pageSize = 10
let currentPage = 1
let filter = {
  id: '',
  name: '',
}

const Main = (props) => {
  const { match, location, history } = props
  const { getFieldDecorator, getFieldsValue, resetFields } = props.form
  const [loading, setLoading] = useState(false)
  const [list, setList] = useState([])
  const [count, setCount] = useState(0)
  const search = parseSearch(location.search)

  useEffect(() => {
    currentPage = search.page ? +search.page : 1
    pageSize = search.pageSize ? +search.pageSize : 10
    filter = {
      id: '',
      name: '',
    }
    getData()
  }, [])

  const getData = () => {
    setLoading(true)
    api.getProcedureList({ limit: pageSize, page: currentPage, ...filter }).then(data => {
      setList(data.tmpls)
      setCount(data.total)
      setLoading(false)
    }).catch(() => setLoading(false))
  }

  const onChangeTable = (pagination, filters, sorter) => {
    currentPage = pagination.current
    pageSize = pagination.pageSize
    history.replace(`${match.path}?page=${currentPage}&pageSize=${pageSize}`)
    getData()
  }

  const formSubmit = (e) => {
    e.preventDefault()
    currentPage = 1
    history.replace(match.path)
    const vals = getFieldsValue()
    filter.id = vals.id
    filter.name = vals.name
    getData()
  }

  const onReset = () => {
    resetFields()
    filter.id = ''
    filter.name = ''
    currentPage = 1
    history.replace(match.path)
    getData()
  }

  return (
    <>
      <div>
        <Form layout="inline" onSubmit={formSubmit}>
          <FormItem label="ID">
            {getFieldDecorator('id', { initialValue: '' })(<Input placeholder="请输入" />)}
          </FormItem>
          <FormItem label="类型">
            {getFieldDecorator('name', { initialValue: '' })(<Input placeholder="请输入" />)}
          </FormItem>
          <FormItem>
            <BtnGroup right style={{ marginTop: 4 }} cancelName="重置" confirmName="查询" onCancel={onReset} />
          </FormItem>
        </Form>
      </div>
      <div className="line"></div>
      <div className="white-line"></div>
      <Alert className="alert-ant" message={`总计：${count}条数据`} type="info" showIcon />
      <Table
        dataSource={list}
        rowKey="id"
        className="table-ant"
        loading={loading}
        pagination={{
          pageSize,
          total: count,
          current: currentPage,
          showQuickJumper: true,
          showSizeChanger: true,
          pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
        }}
        scroll={{ x: 1000 }}
        onChange={onChangeTable}
      >
        <Column title="ID" dataIndex="id" />
        <Column title="审批类型" dataIndex="name" />
        <Column title="审批流数量" dataIndex="num" />
        <Column title="操作" key="set" render={text =>
          <Auth auths={text.operateAuth} code="operate">
            <Link to={`/procedureSetting/${text.id}`} target="_blank"><Icon type="edit" /></Link>
          </Auth>
        } />
      </Table>
    </>
  )
}

export default Form.create()(Main)