
import React from 'react'
import { Icon } from 'antd'

const Node = ({ data, onClickItem, length, index, currentId, fullLine, topLine }) => (
  <div
    key={data.id}
    className="node-wrap"
    onClick={() => onClickItem(data)}
    style={{ cursor: data.type > 2 ? 'default' : 'pointer' }}
  >
    <span className={`node${data.isSkip === 0 ? ' active' : ''}${currentId === data.id ? ' current' : ''}`}>
      {data.name}
    </span>
    {index + 1 !== length && <span className="node-line-vertical"><span className="vertical-line"></span>{!fullLine && <Icon type="caret-down" />}</span>}
  </div>
)

export default Node