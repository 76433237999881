/**
 * 模块名称: 审批流模版
 * @author xuzhongyuan@372163.com
 */

import React from 'react'
import { Icon } from 'antd'
import Node from '../Node'
// import LayoutComp from "../../../../components/Layout";

const Temp = ({ nodes, onClick, currentId, tempId }) => {
  const onClickItem = item => {
    if (item.type > 2 || item.id === currentId) {
      return
    }

    onClick(item)
  }

  const BranchLine = (props) => {
    const list = [];
    for(let i = 0; i < props.branches.length; i++){
      list.push((<Icon key={i} type="arrow-down" />))
      list.push((<span key={"k"+i} className="node-line"></span>))
    }
    list.pop()
    return (
        <div className="row-line">
          {list}
        </div>
    )
  }
  return (
    <div className="temp temp-2">
      {nodes.map((item, index) => {

        if (item.type === 2) {
          return (
            <div className="branch-wrap" key={item.id}>
              <BranchLine branches={item.branchesNodesTree}/>
              <div className="branch">
                {item.branchesNodesTree.map((d, n, source) => {

                  // 条件节点暂时不渲染
                  if (d.type === 5) {
                    return null
                  }

                  return (
                    <div key={n} className="branch-node">
                      {d.map((o, i) => o.type === 5 ? null : <Node key={o.id} data={o} index={i} onClickItem={onClickItem} currentId={currentId} length={d.length} />)}
                      {(() => {
                        // 最大长度的数组
                        let maxLen = 0 
                        // 最大长度的数组的索引
                        let index //eslint-disable-line
                        for (let i = 0; i < source.length; i++) {
                          if (source[i].length > maxLen) {
                            maxLen = source[i].length
                          }
                        }
                        for (let i = 0; i < source.length; i++) {
                          if (source[i].length === maxLen) {
                            index = i
                          }
                        }
                        if (d.length < maxLen) {
                          return <span className="line-more"></span>
                        }
                      })()}
                    </div>
                  )
                })}
              </div>
              <div className="row-line-one">
                <span className="line-border"></span>
                <Icon type="arrow-down" />
              </div>
            </div>
          )
        }

        return (
          <Node key={item.id} data={item} index={index} onClickItem={onClickItem} currentId={currentId} length={nodes.length} />
        )
      })}
    </div>
  )
}

export default Temp