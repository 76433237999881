/**
 * 审批流编辑
 * 
 */

import React, { useState } from 'react'
import Layout from './Layout'
import './assets/style.scss'
import api from '@/api'
import { Route } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { showLoading, hideLoading } from 'global/reducer/loading'

import Temp1 from './Temps/Temp_1'
import Temp2 from './Temps/Temp_2'
import Temp3 from './Temps/Temp_3'

const ProcedureIndex = (props) => {
  const { match, location } = props
  const tempId = location.pathname.split('/').slice(-1)[0]
  const dispatch = useDispatch()

  // 节点
  const [nodes, setNodes] = useState([])

  // 流配置
  const [itemConfig, setItemConfig] = useState(null)
  
  // 节点配置
  const [nodeConfig, setNodeConfig] = useState(null)

  // 已经使用过的公司
  const [disableCorps, setDisableCorps] = useState([])

  // 点击节点
  const onClickNode = data => {
    setNodeConfig(data)
  }

  function renderTree(tree) {
    for (let i in tree) {//eslint-disable-line
      if (tree[i].type === 2) {
        for (let k in tree[i].branchesNodesTree) {//eslint-disable-line
          renderTree(tree[i].branchesNodesTree[k])
        }
      } else if (tree[i].type === 1 && tree[i].prev === 0) {
        // console.log('这是条件:', tree[i].requirement_name.join(','));
      } else {
        // console.log('node 节点 ===>', tree[i])
        nodeList.push(tree[i])
      }
    }
  }

  const nodeList = []
  const dealNode = (tree) => {
    let data = [...tree]
    fn(data)
    function fn(data){
      for(let i in data){
        i = i - 0
        if(data[i].type === 2){
          for(let k in data[i].branchesNodesTree){
            fn(data[i].branchesNodesTree[k])
          }
        }else if(data[i].type === 5){
          fn2(data, i)
        }
      }
    }

    function fn2(data, i){
      i = i - 0
      //判断它的下一项，也就是它的下一个节点：如果为1表示为审批节点（普通节点）将触发条件添加到这里
      //如果为2的话则表示为其分支节点，则将它父级节点的触发条件添加到这里作为前提条件（当满足...条件时：）
      if(data.length - 1 > i){
        if(data[i + 1].type === 1){
          data[i + 1] = { ...data[i + 1], type_5_reqname: data[i].requirement_name }
        }
        if(data[i + 1].type === 2){
          let t = data[i + 1].branchesNodesTree
          for(let j =0; j<t.length; j++){
            /*for(let k=0; k<t[j].length; k++){
              t[j][k] = { ...t[j][k], type_2_parent_reqname: data[i].requirement_name }
            }*/
            //实际是显示在index为1的节点上，少做一次循环
            t[j][1] = { ...t[j][1], type_2_parent_reqname: data[i].requirement_name }
          }
        }
      }
    }
    return data
  }
  const onChangeItem = id => {
    dispatch(showLoading())
    api.getProcedureDetails({ id }).then(data => {
      dispatch(hideLoading())
      let nodeData = dealNode(data.link.nodesTree)
      if (tempId === '4') {
        // 请假审批
        renderTree(nodeData)
        setNodes(nodeList)
      } else {
        setNodes(nodeData)
      }
      setItemConfig({ ...data.link.config, corpRange: data.link.companies_objs })
      setDisableCorps(data.used_companies)
    })
  }

  const currentId = nodeConfig ? nodeConfig.id : null

  const Tmpl = (props) => {
    if(["2","3"].includes(props.tempId)){
      return (
          <Temp1 {...props}/>
      )
    }else if(["4"].includes(props.tempId)){
      return (
          <Temp3 {...props}/>
      )
    }else{
      return (
          <Temp2 {...props}/>
      )
    }
  }

  return (
    <Layout 
      tempId={tempId}
      onChange={onChangeItem}
      nodeConfig={nodeConfig}
      itemConfig={itemConfig}
      disableCorps={disableCorps}
      nodes={nodes}
      setNodeConfig={setNodeConfig}
      onSetNodes={setNodes}
      onClearNodes={() => setNodes([])}
      onClearNodeConfig={() => onClickNode(null)}
      onClearItemConfig={() => setItemConfig(null)}
    >
      <Route path={`${match.path}/${tempId}`} render={props => <Tmpl {...props} tempId={tempId} nodes={nodes} onClick={onClickNode} currentId={currentId} />} />
    </Layout>
  )
}

export default ProcedureIndex